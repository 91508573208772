export const  Languages =  {
    contacts : {'Ru': 'Контакты' , 'En' :'Contacts'},
    catalog : {'Ru': 'Каталог' , 'En' :'Catalog'},
    mainTitle : {'Ru': `Работаем на` , 'En' :'We are working for'},
    mainTitle2 : {'Ru': 'максимальный результат!' , 'En' :'maximum results!'},
    titleDirections : {'Ru': 'Направления деятельности' , 'En' :'Areas of activity'},
    DirectionsItem1 : {'Ru': 'Поковки для ж/д транспорта' , 'En' :'Forgings for railway transport'},
    DirectionsItem2 : {'Ru': 'Поковки для машиностроения' , 'En' :'Forgings for mechanical engineering'},
    DirectionsItem3 : {'Ru': 'Фланцы и штамповки ТПА' , 'En' :'TPA Flanges and stampings'},
    DirectionsItem4 : {'Ru': 'Продукция для грузоподъемной техники' , 'En' :'Products for lifting equipment'},
    allDirections : {'Ru': 'ВСЕ НАПРАВЛЕНИЯ' , 'En' :'ALL DIRECTIONS'},
    address : {'Ru': 'Челябинская область, город Чебаркуль, д.17' , 'En' :'Chelyabinsk region, Chebarkul city, 17'},
    form : {'Ru': 'Форма обратной связи' , 'En' :'Feedback form'},
    formName : {'Ru': '*ФИО' , 'En' :'*FCs'},
    formNumber : {'Ru': '*Контактный номер' , 'En' :'*Contact number'},
    formComment : {'Ru': '*Ваш комментарий' , 'En' :'*Your comment'},
    formSend : {'Ru': 'ОТПРАВИТЬ' , 'En' :'SEND'},
    productCalalog : {'Ru': 'Каталог продукции:' , 'En' :'Product catalog:'},
    nameProduct : {'Ru': 'Наименование товара' , 'En' :'Product name'},
    services1: {'Ru': 'Услуги ковки/штамповки' , 'En' :'Forging/punching services'},
    services2: {'Ru': 'Услуги термообработки' , 'En' :'Heat treatment services'},
    services3: {'Ru': 'Услуги механообработки' , 'En' :'Machining services'},

    ForgingTitle1: {'Ru': 'Ковка/штамповка' , 'En' :'Forging/stamping'},
    ForgingText1: {'Ru': '— технологический процесс, при котором получение и обработка изделия или заготовки получается путем оказания давления технологического инструмента.' , 'En' :'- A technological process in which obtaining and processing a product or workpiece is obtained by applying pressure with a technological tool.'},
    ForgingTitle2: {'Ru': 'Ковкой ' , 'En' :'Forging'},
    ForgingText2: {'Ru': 'называется пластическая деформация, при котором изменение формы и размера достигается в тех местах заготовки, где металл оказывает наименьшее сопротивление (преимущественно углы, торцы).' , 'En' :'is called plastic deformation, in which a change in shape and size is achieved in those areas of the workpiece where the metal has the least resistance (mainly corners, ends).'},
    ForgingText3: {'Ru': 'Работы производятся на оборудовании с МПЧ до Х тнс., изготавливаются поковки до Х кг.' , 'En' :'The works are performed on equipment with MPH up to X tnc. and forgings up to X kg are produced.'},
    ForgingTitle4: {'Ru': 'Штамповкой ' , 'En' :'Stamping'},
    ForgingText4: {'Ru': 'называется обработка металла при помощи штампов, которые задают форму и очертание будущего изделия.' , 'En' :'is the processing of metal with the help of stamps, which set the shape and outline of the future product.'},

    TreatmentTitle1: {'Ru': 'Термообработка' , 'En' :'Heat treatment'},
    TreatmentText1: {'Ru': '— технологический процесс, при котором достигается улучшение положительных свойств и характеристик металла путем нагрева, выдержки при заданной температуре и охлаждения.' , 'En' :'- A technological process in which the positive properties and characteristics of the metal are improved by heating, holding at a given temperature, and cooling.'},
    // TreatmentTitle1: {'Ru': 'Термообработка' , 'En' :'Heat treatment'},
    TreatmentText2: {'Ru': 'Может быть как промежуточным этапом в технологии, например для улучшения свойств ОМД, так и окончательным этапом для задания установленных свойств и характеристик.' , 'En' :'It can be both an intermediate step in the technology, for example to improve the properties of OMD, and the final step to set the established properties and characteristics.'},
    TreatmentText3: {'En': 'LLC "Urals Blacksmith Factory" provides the following services:' , 'Ru' :'ООО «Уральский Кузнечный Завод» оказывает следующие услуги:'},
    TreatmentTitle4: {'Ru': 'Объёмная закалка' , 'En' :'Volumetric hardening'},
    TreatmentText4: {'Ru': 'практически полностью меняет структуру изделия. Связано это с тем, что нагреваются как поверхностные, так и внутренние слои металла. Результатом становится значительное повышение твердости и износостойкости детали;' , 'En' :'changes the structure of the product almost completely. This is due to the fact that both the surface and inner layers of the metal are heated. The result is a significant increase in hardness and wear resistance of the part;'},
    TreatmentTitle5: {'Ru': 'Нормализация стали' , 'En' :'Normalization of steel'},
    TreatmentText5: {'Ru': 'приводит к приобретению сталью мелкозернистой, однородной структуры. Твердость, прочность стали после нормализации выше на 10-15%, чем после отжига;' , 'En' :'leads to acquisition of a fine-grained, homogeneous structure by the steel. The hardness, strength of steel after normalization is 10-15% higher than after annealing;'},
    TreatmentTitle6: {'Ru': 'Отпуск металла' , 'En' :'Metal tempering'},
    TreatmentText6: {'Ru': 'проводится с целью получения более высокой пластичности и снижения хрупкости материала при сохранении приемлемого уровня его прочности;' , 'En' :'is carried out in order to obtain higher ductility and lower brittleness of the material while maintaining an acceptable level of strength;'},
    TreatmentTitle7: {'Ru': 'Отжиг' , 'En' :'Annealing'},
    TreatmentText7: {'Ru': 'металла требуется для облегчения механической обработки, улучшения микроструктуры и достижения большей однородности металла.' , 'En' :'metal is required to facilitate machining, improve the microstructure and achieve greater metal homogeneity.'},

    MachiningTitle1: {'Ru': 'Механическая обработка' , 'En' :'Mechanical processing'},
    MachiningText1: {'Ru': '— технологический процесс, при котором обработка изделий или заготовок происходит путем применения режущего инструмента (токарный, фрезерный и т. д.).' , 'En' :'- technological process in which the processing of products or workpieces takes place through the use of cutting tools (turning, milling, etc.).'},
    MachiningText2: {'Ru': 'ООО «Уральский Кузнечный Завод» выполняет следующие виды работ:' , 'En' :'LLC "Urals Blacksmith Factory" performs the following types of work:'},
    MachiningTitle3: {'Ru': 'Горизонтально расточные работы' , 'En' :'Horizontal boring work'},
    MachiningText3: {'Ru': 'выполняются на станках горизонтально-расточной группы, позволяющих осуществлять обработку крупноразмерных заготовок;' , 'En' :'are carried out on the machines of the horizontal boring group that allow machining of large-sized workpieces;'},
    MachiningTitle4: {'Ru': 'Строгальные работы' , 'En' :'Planing work'},
    MachiningText4: {'Ru': 'осуществляются посредством цикличных проходов резца со специальным профилем режущей кромки по поверхности заготовки. Размер обрабатываемых изделий до;' , 'En' :'are carried out by means of cyclic passes of a cutter with a special cutting edge profile over the surface of the workpiece. The size of the workpieces to be machined is up to;'},
    MachiningTitle5: {'Ru': 'Фрезерная обработка на станках с ЧПУ' , 'En' :'Milling on CNC machines'},
    MachiningText5: {'Ru': '— вертикальный фрезерный обрабатывающий центр с ЧПУ (Размера стола 1600x650мм);' , 'En' :'- vertical CNC milling center (table size 1600x650mm);'},
    MachiningTitle6: {'Ru': 'Фрезерная обработка на универсальных станках' , 'En' :'Milling on universal machines'},
    MachiningText6: {'Ru': '(Размер стола ХХХХхХХмм);' , 'En' :'(Table size XXXXXXXXmm);'},
    MachiningTitle7: {'Ru': 'Токарная обработка на универсальных станках' , 'En' :'Turning on universal machines'},
    MachiningText7: {'Ru': '— карусельный станок, диаметр обрабатываемых изделий до 2.300мм, токарный станок ДИП 500 (1М65), РМЦ 5.000мм.' , 'En' :'- Carousel machine, diameter of workpieces up to 2.300mm, lathe DIP 500 (1M65), 5.000mm.'},

    service: {'Ru': 'Услуги:' , 'En' :'Services:'},
    serviceNav1: {'Ru': 'Ковка/штамповка' , 'En' :'Forging/stamping'},
    serviceNav2: {'Ru': 'Термообработка' , 'En' :'Heat treatment'},
    serviceNav3: {'Ru': 'Механообработка' , 'En' :'Machining'},
    
}